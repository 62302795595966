import { Button, Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, Select, FormControl, FormLabel } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import InputSearch from 'components/InputSearch/InputSearch';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './Components/Row';
import React, { useState, useEffect } from 'react';
import Loading from 'components/Layout/Loading';
import { checkLogin } from '../../../utils/authentication';
import { TablePagination } from '@trendmicro/react-paginations';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import { formatDate } from 'utils/helpers';
function History8B() {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [data888B, setData888B] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [filterDay, setFilterDay] = useState({
    startDate: '',
    endDate: '',
  });
  const handleSearchKeywordChange = event => {
    setSearchKeyword(event.target.value);
  };
  const handleStatus = event => {
    setStatus(event.target.value);
  };
  const isLoggedIn = checkLogin();
  const [{ data, loading }, refetch] = useAxios({
    url: `${API_ROUTES.TrackingSendEmails}?brand=888B`,
    params: filter,
  });
  const handleFilter = () => {
    const startDate = moment(filterDay.startDate).startOf('day').toISOString();
    const endDate = moment(filterDay.endDate).endOf('day').toISOString();
    setFilter({ ...initialFilter, status, searchKeyword, start: startDate, end: endDate });
  };
  const clearFilter = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
    setStatus('');
    setFilterDay({
      startDate: '',
      endDate: '',
    });
  };

  const onChangeDate = (fieldName, date) => {
    setFilterDay(prevFilter => ({
      ...prevFilter,
      [fieldName]: date,
    }));
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            History 888B
          </Text>
        </CardHeader>
        <Flex flexWrap="wrap" marginBottom="30px" flexDirection="row">
          <FormControl mt="2px" display="flex" alignItems="center" maxW="300px">
            <FormLabel m="0" pr="10px">
              Search
            </FormLabel>
            <InputSearch value={searchKeyword} onChange={handleSearchKeywordChange} />
          </FormControl>
          <FormControl mt="2px" display="flex" alignItems="center" maxW="350px">
            <FormLabel m="0" pr="10px">
              Status
            </FormLabel>
            <Select maxH="30px" m="10px" value={status} onChange={handleStatus}>
              <option value=""></option>
              <option value="-1">Thất Bại</option>
              <option value="0">Thành Công</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex>
          <FormControl mt="2px" display="flex" alignItems="center" maxW="300px" mr="12px">
            <FormLabel m="0" pr="10px">
              Start Date
            </FormLabel>
            <DatePicker selectedDate={filterDay.startDate} onChange={date => onChangeDate('startDate', date)} />
          </FormControl>
          <FormControl mt="2px" display="flex" alignItems="center" maxW="300px" mr="12px">
            <FormLabel m="0" pr="10px">
              End Date:
            </FormLabel>
            <DatePicker
              selectedDate={filterDay.endDate}
              minDate={filterDay.startDate}
              maxDate={new Date(formatDate(moment(filterDay.startDate).add(6, 'days')))}
              onChange={date => onChangeDate('endDate', date)}
            />
          </FormControl>
          <Flex>
            <Button maxH="40px" m="10px" onClick={clearFilter} variant="primary">
              Cài lại
            </Button>
            <Button marginTop="8px" variant="primary" maxH="40px" m="10px" onClick={handleFilter} fontWeight="bolder">
              Lọc
            </Button>
          </Flex>
        </Flex>

        <CardBody w="100%" overflowX="auto">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th w="38%" pl="108px" pr="100px" borderColor={borderColor} color="gray.400">
                      To
                    </Th>
                    <Th borderColor={borderColor} pl="55px" pr="0" color="gray.400">
                      Status
                    </Th>
                    <Th pl="54px" borderColor={borderColor} color="gray.400">
                      Sent At
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map(row => {
                    return (
                      <Row
                        id={row._id}
                        from={row.emailContent}
                        result={row.result}
                        day={moment.utc(row.createdAt).subtract('1', 'hours').format('YYYY-MM-DD HH:mm:ss')}
                      />
                    );
                  })}
                </Tbody>
              </Table>
              <Flex justifyContent="flex-end">
                <TablePagination
                  type="full"
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={({ page, pageLength }) => {
                    // console.log(page);
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                  prevPageRenderer={() => <i className="fa fa-angle-left" />}
                  nextPageRenderer={() => <i className="fa fa-angle-right" />}
                />
              </Flex>
            </>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default History8B;
