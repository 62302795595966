import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import useAxios from 'axios-hooks';
import InputController from 'components/Form/InputController';
import EditorController from 'components/Form/EditorController';
import { mappingOptionSelect } from 'utils/mapping';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES } from 'utils/constant';
import { omit } from 'lodash';
import { ModalType } from 'utils/constant';
import { ClassificationOptions } from 'utils/constant';
import { ClassificationKey } from 'utils/constant';
import { useUserState } from 'context/UserContext';

let DataOmit = ['template', 'classification', 'customer', 'channel'];

const EmailForm = ({ setCurrentStep, onClose, refetchData }) => {
  const toast = useToast();
  const [channelOption, setChannelOption] = useState([]);
  const [templateOption, setTemplateOption] = useState([]);
  const [customerOption, setCustomerOption] = useState([]);
  const { userInfo } = useUserState();
  const [classificationSelected, setClassificationSelected] = useState({
    label: 'Khách hàng',
    value: ClassificationKey.Customers,
  });

  const [{ data: channelData }] = useAxios(
    {
      url: API_ROUTES.ChannelActive,
    },
    {
      useCache: false,
    }
  );
  const [{ data: templateData }] = useAxios(
    {
      url: API_ROUTES.EmailTemplate,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: sendChannelLoading }, sendMailChannelApi] = useAxios(
    {
      url: API_ROUTES.SendMailChannel,
      method: 'post',
    },
    { manual: true }
  );
  const [{ data: customerData }] = useAxios(
    {
      url: API_ROUTES.CreateCustomer,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: sendCustomerLoading }, sendMailCustomerApi] = useAxios(
    {
      url: API_ROUTES.SendMailCustomer,
      method: 'post',
    },
    { manual: true }
  );

  useEffect(() => {
    if (userInfo?.role !== 'admin') {
      const excludeFields = ['from', 'smtpId', 'replyTo'];
      DataOmit = DataOmit.concat(excludeFields);
    }
  }, [userInfo?.role]);
  
  useEffect(() => {
    setChannelOption(mappingOptionSelect(channelData?.data));
  }, [channelData]);

  useEffect(() => {
    setTemplateOption(mappingOptionSelect(templateData?.data, 'name', 'content'));
  }, [templateData]);

  useEffect(() => {
    setCustomerOption(mappingOptionSelect(customerData?.data, 'email'));
  }, [customerData]);

  const { control, reset, handleSubmit, getValues, setValue } = useFormContext();

  const handleSuccess = () => {
    toast({
      title: 'Đang gửi email vui lòng đợi!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
    reset();
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Tạo và gửi email chiến dịch không thành công',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    if (values?.classification?.value === ClassificationKey.Customers) {
      sendMailCustomerApi({
        data: omit({ ...values, customerIds: values?.customer?.map(item => item?.value).join(',') }, DataOmit),
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });

      return;
    }

    sendMailChannelApi({ data: omit({ ...values, channelId: values?.channel?.value }, DataOmit) })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const onChangeTemplate = template => {
    setValue('body', template?.value);
  };

  const onChangeClassification = value => {
    setClassificationSelected(value);
  };

  return (
    <Box>
      <Flex>
        <FormLabel minW="150px">Chiến dịch</FormLabel>
        <Text>{getValues('campaignName')}</Text>
      </Flex>
      <InputController
        styleContainer={{ pt: '4', display: 'flex' }}
        styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
        control={control}
        name="sender"
        label="Định danh"
        isRequired
      />
      <InputController
        styleContainer={{ pt: '4', display: 'flex' }}
        styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
        control={control}
        name="subject"
        label="Tiêu đề"
        isRequired
      />
      {userInfo?.role === 'admin' && (
        <>
        <InputController
          styleContainer={{ pt: '4', display: 'flex' }}
          styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
          control={control}
          name="from"
          label="Người gửi"
          isRequired
        />
          <InputController
            styleContainer={{ pt: '4', display: 'flex' }}
            styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
            control={control}
            name="smtpId"
            label="SMTP ID"
            isRequired
          />
          <InputController
            styleContainer={{ pt: '4', display: 'flex' }}
            styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
            control={control}
            name="replyTo"
            label="Reply To"
          />
        </>
      )}

      <SelectController
        styleContainer={{ pt: '4', display: 'flex' }}
        styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
        control={control}
        options={ClassificationOptions}
        isRequired
        name="classification"
        label="Phân loại"
        onChange={onChangeClassification}
      />
      {classificationSelected?.value === ClassificationKey.Group && (
        <SelectController
          styleContainer={{ pt: '4', display: 'flex' }}
          styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
          control={control}
          options={channelOption}
          name="channel"
          label="Nhóm"
          isRequired
        />
      )}
      {classificationSelected?.value === ClassificationKey.Customers && (
        <SelectController
          styleContainer={{ pt: '4', display: 'flex' }}
          styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
          control={control}
          options={customerOption}
          name="customer"
          label="Khách hàng"
          isRequired
          isMulti
        />
      )}
      <SelectController
        styleContainer={{ pt: '4', display: 'flex' }}
        styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
        control={control}
        options={templateOption}
        name="template"
        label="Template"
        onChange={onChangeTemplate}
      />
      <FormControl pt="4">
        <EditorController control={control} name="body" />
      </FormControl>
      <Flex alignItems="center" justifyContent="end" my={4}>
        <Button
          onClick={() => {
            onClose?.(ModalType.Add);
          }}
        >
          Hủy
        </Button>
        <Button colorScheme="blue" ml={3} isLoading={sendChannelLoading || sendCustomerLoading} onClick={handleSubmit(onSubmit)}>
          Tạo
        </Button>
      </Flex>
    </Box>
  );
};

export default EmailForm;
