import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  Text,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { API_ROUTES, ModalType } from 'utils/constant';

const DeleteTemplateModal = ({ isOpen, templateDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading }, deleteTemplateApi] = useAxios(
    {
      url: API_ROUTES.DeleteEmailTemplate,
      method: 'post',
    },
    { manual: true }
  );

  const handleSuccess = () => {
    toast({
      title: 'Xóa Template thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Delete);
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Xóa Template không thành công',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onDeleteTemplate = () => {
    toast.closeAll();

    deleteTemplateApi({
      data: { id: templateDetail?.id },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Delete);
        }}
        isOpen={isOpen}
        isCentered
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="uppercase">Xóa Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Bạn có chắc chắn muốn xóa Template không?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Delete);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={loading} onClick={onDeleteTemplate}>
              Xóa
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteTemplateModal;
