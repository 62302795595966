import React, { useEffect } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import Row from './components/Row';
import { API_ROUTES } from 'utils/constant';

const InitFilter = {
  pageSize: 10,
  pageIndex: 0,
  isSent: false,
};

const EmailFail = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const campaignId = query.get('campaignId');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const [{ data, loading, error }, getEmail] = useAxios(
    {
      url: API_ROUTES.EmailCampaignFail,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (campaignId) {
      onGetEmail({});
    }
  }, [campaignId]);

  const onGetEmail = params => {
    getEmail({
      params: {
        ...InitFilter,
        ...params,
        campaignId,
      },
    });
  };

  return (
    <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Email
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  Chiến dịch
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Người nhận
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Ngày gửi
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Trạng thái
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data?.map((row, index, arr) => {
                return <Row key={row._id} emailDetail={row} isLast={index === arr.length - 1 ? true : false} />;
              })}
              {isEmpty(data?.data) && !loading && (
                <Tr>
                  <Td colSpan="8">
                    <Box textAlign="center" height="200px" pt="24px">
                      Không có dữ liệu
                    </Box>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          {!isEmpty(data?.data) && data?.pagination?.count > 10 && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={data?.pagination?.page}
                pageLength={data?.pagination?.pageSize}
                totalRecords={data?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  onGetEmail({ pageSize: pageLength, pageIndex: page - 1 });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default EmailFail;
