import React from 'react';
import { Avatar, Checkbox, Flex, IconButton, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { ModalType } from 'utils/constant';

function Row({ template, isLast, handelUpdateTemplate }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', 'navy.900');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} w="70%" pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {template?.name}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateTemplate(template, ModalType.Add);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateTemplate(template, ModalType.Preview);
            }}
          >
            <ViewIcon />
          </IconButton>
          <IconButton
            p={2}
            textColor="red"
            bg="transparent"
            onClick={() => {
              handelUpdateTemplate(template, ModalType.Delete);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
