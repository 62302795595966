import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import CustomerRow from 'components/Customer/CustomerRow';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CustomerRegisterDialog from 'components/Customer/CustomerRegisterDialog';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, initialFilter, ModalType } from 'utils/constant';
import { mappingCustomers } from 'utils/mapping';
import { downloadFile } from 'utils/helpers';
import DeleteCustomerDialog from 'components/Customer/DeleteCustomerDialog';
import ImportCustomerDialog from 'components/Customer/ImportCustomerDialog';
import InputSearch from 'components/InputSearch/InputSearch';

const Customer = () => {
  const toast = useToast();
  const [filter, setFilter] = useState({ ...initialFilter });
  const [customers, setCustomers] = useState([]);
  const [customerDetail, setCustomerDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { search } = useLocation();
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const { isOpen: isOpenImportModal, onOpen: onOpenImportModal, onClose: onCloseImportModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
      [ModalType.Import]: onOpenImportModal,
    }),
    [onOpenAddModal, onOpenDeleteModal, onOpenImportModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
      [ModalType.Import]: onCloseImportModal,
    }),
    [onCloseAddModal, onCloseDeleteModal, onCloseImportModal]
  );
  const query = new URLSearchParams(search);
  const channelId = query.get('channelId');
  const channelName = query.get('channelName');

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.CustomerByChannel(channelId),
      params: filter,
    },
    {
      manual: true,
    }
  );
  const [{ loading: exportLoading }, exportCustomerApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportCustomer,
      params: filter,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );
  const [{ loading: downloadLoading }, downloadTemplateApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportTemplateCustomer,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  useEffect(() => {
    handleRefetchData();
  }, []);

  const handelUpdateUser = (customerDetail, modalType) => {
    setCustomerDetail(customerDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    setCustomerDetail(null);
    closeModal?.[modalType]?.();
  };

  const onExportCustomer = () => {
    exportCustomerApi()
      .then(response => {
        downloadFile(response?.data, 'customer');
        toast({
          title: 'Export thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Export không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onDownloadTemplate = () => {
    downloadTemplateApi()
      .then(response => {
        downloadFile(response?.data, 'customer-template');
        toast({
          title: 'Tải template thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Tải template không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter },
    })
      .then(res => {
        setCustomers(mappingCustomers(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Khách hàng
            </Text>
            <Flex pt="22px" justifyContent="space-between">
              <Flex flex="1">
                <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
              </Flex>
              <Flex>
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenAddModal}>
                  Thêm khách hàng
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenImportModal}>
                  Import
                </Button>
                <Button variant="primary" maxH="30px" m="10px" isLoading={exportLoading} onClick={onExportCustomer}>
                  Export
                </Button>
                <Button variant="primary" maxH="30px" m="10px" isLoading={downloadLoading} onClick={onDownloadTemplate}>
                  Tải template
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    Tên
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Code
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {customers?.map((row, index, arr) => {
                  return (
                    <CustomerRow
                      key={row.id}
                      user={row}
                      isLast={index === arr.length - 1 ? true : false}
                      // refetch={refetch}
                      handelUpdateUser={handelUpdateUser}
                    />
                  );
                })}
                {isEmpty(customers) && !loading && (
                  <Tr>
                    <Td colSpan="4">
                      <Box textAlign="center" height="200px" pt="24px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            {!isEmpty(customers) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setCustomers(mappingCustomers(res?.data?.data));
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && (
        <CustomerRegisterDialog
          customerDetail={customerDetail}
          isOpen={isOpenAddModal}
          channelName={channelName}
          channelId={channelId}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      <DeleteCustomerDialog
        customerDetail={customerDetail}
        isOpen={isOpenDeleteModal}
        onClose={handelCloseModal}
        refetchData={handleRefetchData}
      />
      {isOpenImportModal && (
        <ImportCustomerDialog
          isOpen={isOpenImportModal}
          channelName={channelName}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
    </>
  );
};

export default Customer;
