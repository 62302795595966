import React, { useMemo, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InputController from 'components/Form/InputController';
import { CreateCampaignStep } from 'utils/constant';
import EmailForm from './EmailForm';
import { ModalType } from 'utils/constant';
import { ClassificationKey } from 'utils/constant';

const defaultValues = {
  campaignName: '',
  sender: '',
  subject: '',
  channel: undefined,
  body: '',
  replyTo: '',
  classification: {
    label: 'Khách hàng',
    value: ClassificationKey.Customers,
  },
  customer: undefined,
};

const CreateCampaign = ({ isOpen, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const [currentStep, setCurrentStep] = useState(CreateCampaignStep.FirstStep);

  const schema = useMemo(
    () => ({
      [CreateCampaignStep.FirstStep]: yup.object().shape({
        campaignName: yup.string().required('Vui lòng nhập tên'),
      }),
      [CreateCampaignStep.SecondStep]: yup.object().shape({
        sender: yup.string().required('Vui lòng nhập định danh'),
        subject: yup.string().required('Vui lòng nhập tiêu đề'),
        channel: yup
          .object()
          .nullable()
          .when('classification', {
            is: classification => (classification?.value === ClassificationKey.Group ? true : false),
            then: () => yup.object().nullable().required('Vui lòng chọn nhóm'),
          }),
        customer: yup
          .array()
          .nullable()
          .when('classification', {
            is: classification => (classification?.value === ClassificationKey.Customers ? true : false),
            then: () => yup.array().nullable().required('Vui lòng chọn khách hàng'),
          }),
      }),
    }),
    []
  );

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema[currentStep]),
  });

  const onNextStep = values => {
    setCurrentStep(CreateCampaignStep.SecondStep);
  };

  return (
    <>
      <AlertDialog
        size={currentStep === CreateCampaignStep.FirstStep ? 'md' : '4xl'}
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        trapFocus={false}
        {...(currentStep === CreateCampaignStep.FirstStep && { isCentered: true })}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader textTransform="uppercase">Tạo chiến dịch</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <FormProvider {...methods}>
                <form>
                  {currentStep === CreateCampaignStep.FirstStep && (
                    <InputController control={methods.control} name="campaignName" label="Tên" isRequired />
                  )}
                  {currentStep === CreateCampaignStep.SecondStep && (
                    <EmailForm setCurrentStep={setCurrentStep} onClose={onClose} refetchData={refetchData} />
                  )}
                </form>
              </FormProvider>
            </AlertDialogBody>
            {currentStep === CreateCampaignStep.FirstStep && (
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => {
                    onClose?.(ModalType.Add);
                  }}
                >
                  Hủy
                </Button>
                <Button colorScheme="blue" ml={3} onClick={methods.handleSubmit(onNextStep)}>
                  Tiếp theo
                </Button>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CreateCampaign;
