import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Text,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';

const DeleteSMTPServerModal = ({ isOpen, settingDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading }, deleteSettingApi] = useAxios(
    {
      url: API_ROUTES.DeleteSMTPServer,
      method: 'post',
    },
    { manual: true }
  );

  const handleSuccess = () => {
    toast({
      title: 'Xóa SMTP Server thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Delete);
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Xóa SMTP Server không thành công',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onDeleteChannel = () => {
    toast.closeAll();
    deleteSettingApi({
      data: { id: settingDetail?._id },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Delete);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Xóa SMTP Server</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text>Bạn có chắc chắn muốn xóa SMTP Server không?</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Delete);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={loading} onClick={onDeleteChannel}>
              Xóa
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DeleteSMTPServerModal;
