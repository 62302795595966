import { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CreatableSelect } from 'chakra-react-select';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';
import { mappingOptionSelect } from 'utils/mapping';

const ChannelSelect = ({ selectClassName, customStyles, error, ...props }, ref) => {
  const [options, setOptions] = useState([]);

  const [{ data: channelData }] = useAxios(
    {
      url: API_ROUTES.ChannelActive,
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    setOptions(mappingOptionSelect(channelData?.data));
  }, [channelData]);

  return (
    <div>
      <CreatableSelect
        ref={ref}
        className={classNames('form-control', selectClassName)}
        classNamePrefix="select"
        isClearable={false}
        options={options}
        placeholder="Tìm hoặc tạo nhóm..."
        createOptionPosition="first"
        formatCreateLabel={inputValue => `Thêm \'${inputValue}\'`}
        {...props}
      />
    </div>
  );
};

export default forwardRef(ChannelSelect);
