import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import DatePicker from 'components/DatePicker/DatePicker';
import { getInitFilerChart } from 'utils/helpers';
import Row from './Components/Row';
import { isBoolean, isEmpty } from 'lodash';
import { Select } from 'chakra-react-select';
import { MailStatusOption } from 'utils/constant';
import { MailStatusKey } from 'utils/constant';

const initFiler = getInitFilerChart();

const getStatus = status => {
  if (status === 'true') {
    return {
      label: 'Đã đọc',
      value: MailStatusKey.Read,
    };
  }

  if (status === 'false') {
    return {
      label: 'Đã đọc',
      value: MailStatusKey.UnRead,
    };
  }

  return {
    label: 'Tất cả',
    value: MailStatusKey.All,
  };
};

const EmailOpened = ({ data, startDate, endDate, status, pageSize, pageIndex, handleFilter }) => {
  const [filter, setFilter] = useState({
    ...initFiler,
    pageSize: 10,
    pageIndex: 0,
    status: {
      label: 'Tất cả',
      value: MailStatusKey.All,
    },
  });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    setFilter(prev => ({
      ...prev,
      startDate: startDate ? new Date(startDate) : prev.startDate,
      endDate: endDate ? new Date(endDate) : prev.endDate,
      pageSize: pageSize ? pageSize : prev.pageSize,
      pageIndex: pageIndex ? pageIndex : prev.pageIndex,
      status: getStatus(status),
    }));
  }, [startDate, endDate, status, pageSize, pageIndex]);

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      [type]: date,
    }));
  };

  const onFilter = () => {
    handleFilter(filter);
  };

  return (
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px">
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Email
        </Text>
        <Flex mt="12px" flexWrap="wrap">
          <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
            <FormLabel m="0" pr="10px">
              Start Date:
            </FormLabel>
            <DatePicker selectedDate={filter.startDate} onChange={date => onChangeDate('startDate')(date)} />
          </FormControl>
          <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
            <FormLabel m="0" pr="10px">
              End Date:
            </FormLabel>
            <DatePicker selectedDate={filter.endDate} minDate={filter.startDate} onChange={date => onChangeDate('endDate')(date)} />
          </FormControl>
          <FormControl display="flex" alignItems="center" maxW="300px" mr="12px" flex="1">
            <FormLabel m="0" pr="10px">
              Trạng thái:
            </FormLabel>
            <Select
              options={MailStatusOption}
              value={filter.status}
              placeholder={'Chọn'}
              chakraStyles={{
                container: (provided, state) => ({
                  ...provided,
                  flex: 1,
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: 10,
                }),
              }}
              onChange={e => {
                setFilter(prev => ({
                  ...prev,
                  status: e,
                }));
              }}
            />
          </FormControl>
          <Button variant="primary" fontSize="14px" onClick={onFilter}>
            Lọc
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              <Th pl="0px" borderColor={borderColor} color="gray.400">
                Email
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Ngày gửi
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Trạng thái
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.data?.map((row, index, arr) => {
              return <Row key={row.id} emailDetail={row} isLast={index === arr.length - 1 ? true : false} />;
            })}
          </Tbody>
        </Table>
        {!isEmpty(data?.data) && (
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={data?.pagination?.page}
              pageLength={data?.pagination?.pageSize}
              totalRecords={data?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter(prev => ({
                  ...prev,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                }));
                handleFilter({ ...filter, pageSize: pageLength, pageIndex: page - 1 });
              }}
            />
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

export default EmailOpened;
