import React, { useEffect, useState } from 'react';
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import { getInitFilerChart } from 'utils/helpers';
import { formatDate } from 'utils/helpers';
import { BrandMailKey } from 'utils/constant';
import EmailOpened from 'components/EmailOpened/EmailOpened';
import { MailStatusKey } from 'utils/constant';

const initFiler = getInitFilerChart();

const SystemEmail = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const brand = query.get('brand');
  const isOpen = query.get('isOpen');
  const pageSize = query.get('pageSize');
  const pageIndex = query.get('pageIndex');

  const [{ data, loading, error }, getEmail] = useAxios(
    {
      url: API_ROUTES.WaeboxEmailSent,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getEmail({
      params: {
        pageSize: pageSize || 10,
        pageIndex: pageIndex || 0,
        startDate: startDate ? startDate : formatDate(initFiler.startDate, 'YYYY-MM-DD'),
        endDate: endDate ? endDate : formatDate(initFiler.endDate, 'YYYY-MM-DD'),
        isOpen,
        brand: brand || BrandMailKey.Eday,
      },
    });
  }, [startDate, endDate, brand, isOpen, pageSize, pageIndex]);

  const handleFilter = params => {
    getEmail({
      params: {
        ...params,
        startDate: startDate ? startDate : formatDate(params.startDate, 'YYYY-MM-DD'),
        endDate: endDate ? endDate : formatDate(params.endDate, 'YYYY-MM-DD'),
        brand: brand || BrandMailKey.Eday,
        ...(params.status?.value !== MailStatusKey.All && { isOpen: params.status?.value === MailStatusKey.Read ? true : false }),
      },
    }).then(() => {
      const isOpen = params.status?.value === MailStatusKey.All ? '' : params.status?.value === MailStatusKey.Read ? true : false;

      history.replace({
        pathname: '/admin/system-email',
        search: `?startDate=${formatDate(params.startDate, 'YYYY-MM-DD')}&endDate=${formatDate(params.endDate, 'YYYY-MM-DD')}&brand=${
          brand || BrandMailKey.Eday
        }&isOpen=${isOpen}&pageSize=${params.pageSize}&pageIndex=${params.pageIndex}`,
      });
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        <Flex pb="20px">
          <Button
            variant="solid"
            maxH="40px"
            onClick={() => {
              history.goBack();
            }}
          >
            Trở lại
          </Button>
        </Flex>
        <EmailOpened
          data={data}
          startDate={startDate}
          endDate={endDate}
          status={isOpen}
          pageSize={pageSize}
          pageIndex={pageIndex}
          handleFilter={handleFilter}
        />
      </Flex>
    </>
  );
};

export default SystemEmail;
