import React, { useState, useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import useAxios from 'axios-hooks';
import { ModalType, API_ROUTES } from 'utils/constant';
import InputController from 'components/Form/InputController';
import { schemaTest } from 'utils/validation';

const defaultValues = {
  authUserEmail: '',
  authUserPassword: '',
};

const AddEmailBackupModal = ({ isOpen, smtpId, emailBackup, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading: createLoading }, createEmailBackupApi] = useAxios(
    {
      url: API_ROUTES.SMTPServerBackupEmail,
      method: 'post',
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateEmailBackupApi] = useAxios(
    {
      url: API_ROUTES.SMTPServerUpdateBackupEmail,
      method: 'post',
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    authUserEmail: yup
      .string()
      .required('Vui lòng nhập email')
      .test('isValidEmail', 'Email không hợp lệ', value => {
        return schemaTest.isValidEmail(value);
      }),
    authUserPassword: yup
      .string()
      .nullable()
      .required('Vui lòng nhập mật khẩu')
      .test('isValidPassword', 'Mật khẩu không hợp lệ', value => {
        return schemaTest.isValidPassword(value);
      }),
  });
  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  useEffect(() => {
    if (!isEmpty(emailBackup)) {
      reset({
        backupId: emailBackup?._id,
        authUserEmail: emailBackup?.authUserEmail,
        authUserPassword: emailBackup?.authUserPassword,
      });
    }
  }, [emailBackup]);

  const handleSuccess = () => {
    toast({
      title: `${!isEmpty(emailBackup) ? 'Cập nhập' : 'Tạo'} email backup thành công`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.EmailBackup);
  };

  const handleError = error => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${!isEmpty(emailBackup) ? 'Cập nhập' : 'Tạo'} email backup không thành công`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    if (!isEmpty(emailBackup)) {
      updateEmailBackupApi({
        data: { ...values, id: smtpId },
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });

      return;
    }

    createEmailBackupApi({
      data: { ...values, id: smtpId },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          reset();
          onClose?.(ModalType.EmailBackup);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Email Backup</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController styleContainer={{ pt: '4' }} control={control} name="authUserEmail" label="User Email" isRequired />
              <InputController styleContainer={{ pt: '4' }} control={control} name="authUserPassword" label="User Password" isRequired />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                reset();
                onClose?.(ModalType.EmailBackup);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={createLoading || updateLoading} onClick={handleSubmit(onSubmit)}>
              Thêm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddEmailBackupModal;
