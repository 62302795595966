import React from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '@chakra-ui/react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { ModalType } from 'utils/constant';
import { TINY_API_KEY } from 'utils/constant';

const PreviewTemplateModal = ({ isOpen, templateDetail, onClose }) => {
  const cancelRef = React.useRef();

  return (
    <>
      <Modal
        size="4xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Preview);
        }}
        isOpen={isOpen}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="uppercase">Preview Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TinyEditor
              apiKey={TINY_API_KEY}
              init={{
                height: 500,
                menubar: false,
                toolbar: false,
                readonly: true,
                language: 'vi',
                elementpath: false,
                branding: false,
              }}
              value={templateDetail?.content}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Preview);
              }}
            >
              Hủy
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewTemplateModal;
