import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Button, Flex, Grid } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import { checkLogin } from '../../../utils/authentication';
import { useHistory } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import { formatDate } from 'utils/helpers';
import { BrandMailKey } from 'utils/constant';
import { getInitFilerChart } from 'utils/helpers';
import DailyChart from 'components/DailyChart/DailyChart';

const initFiler = getInitFilerChart();

export default function SystemEmailOpened() {
  const isLoggedIn = checkLogin();
  const history = useHistory();

  const [{ data: statistical888B }, getStatistical888B] = useAxios(
    {
      url: API_ROUTES.WaeboxEmailOpenedDaily,
    },
    { manual: true }
  );
  const [{ data: statistical8Day }, getStatistical8Day] = useAxios(
    {
      url: API_ROUTES.WaeboxEmailOpenedDaily,
    },
    { manual: true }
  );

  useEffect(() => {
    getStatistical888B({
      params: {
        startDate: formatDate(initFiler.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(initFiler.endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Ebbb,
      },
    });
    getStatistical8Day({
      params: {
        startDate: formatDate(initFiler.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(initFiler.endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Eday,
      },
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/signin');
    }
  }, [isLoggedIn, history]);

  const handleGetNewData888B = (startDate, endDate) => {
    getStatistical888B({
      params: {
        startDate: formatDate(startDate, 'YYYY-MM-DD'),
        endDate: formatDate(endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Ebbb,
      },
    });
  };

  const handleGetNewData8Day = (startDate, endDate) => {
    getStatistical8Day({
      params: {
        startDate: formatDate(startDate, 'YYYY-MM-DD'),
        endDate: formatDate(endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Eday,
      },
    });
  };

  const onClickChart = brand => (startDate, endDate) => {
    history.push({
      pathname: '/admin/system-email',
      search: `?startDate=${formatDate(startDate, 'YYYY-MM-DD')}&endDate=${formatDate(endDate, 'YYYY-MM-DD')}&brand=${brand}&isOpen=true`,
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        <Flex pb="20px">
          <Button
            variant="solid"
            maxH="40px"
            onClick={() => {
              history.goBack();
            }}
          >
            Trở lại
          </Button>
        </Flex>
        <Grid templateColumns={{ sm: '1fr' }} templateRows={{ lg: 'repeat(2, auto)' }} gap="20px">
          <Card p="0px" maxW={{ sm: '320px', md: '100%' }}>
            <DailyChart
              title={`Thống kê Email ${BrandMailKey.Eday} đã đọc`}
              labelChart="Email đã đọc"
              dataChart={statistical8Day?.data || []}
              getNewData={handleGetNewData8Day}
              onClickChart={(startDate, endDate) => {
                onClickChart(BrandMailKey.Eday)(startDate, endDate);
              }}
            />
          </Card>
          <Card p="0px" maxW={{ sm: '320px', md: '100%' }}>
            <DailyChart
              title={`Thống kê Email ${BrandMailKey.Ebbb} đã đọc`}
              labelChart="Email đã đọc"
              dataChart={statistical888B?.data || []}
              getNewData={handleGetNewData888B}
              onClickChart={(startDate, endDate) => {
                onClickChart(BrandMailKey.Ebbb)(startDate, endDate);
              }}
            />
          </Card>
        </Grid>
      </Flex>
    </>
  );
}
