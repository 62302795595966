import React, { useState, useEffect } from 'react';
import { Flex, Text, Collapse, Box, useDisclosure, useToast, Spacer, Icon, Button } from '@chakra-ui/react';
import { BsXCircleFill } from 'react-icons/bs';
import { axiosPost } from 'utils/api';
import { ROOT_API } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import AddEmailBackupModal from './AddEmailBackupModal';

function ExpandedRow({ data, emailBackup, smtpId, refetch }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEmail, setSelectedEmail] = useState();

  const handleEditEmailBackup = email => {
    onOpen();
    setSelectedEmail(email);
  };

  const handleDeleteEmail = async (e, email) => {
    e.stopPropagation();
    if (window.confirm('Are you sure to remove this email')) {
      try {
        const dataDelete = {
          id: smtpId,
          backupId: email?._id,
        };
        const response = await axiosPost(ROOT_API + API_ROUTES.SMTP_SERVER_DELETE_BACKUP_EMAIL, dataDelete);
        if (response?.data?.code == 0) {
          toast({
            title: 'Delete Email Backup Successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          refetch();
        }
      } catch (error) {
        console.log(error);
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Delete Email Backup Fail',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Collapse in={true}>
        <Flex columnGap={2}>
          {emailBackup?.length
            ? emailBackup.map((email, index) => (
                <Box
                  cursor={'pointer'}
                  display={'flex'}
                  flexDirection={'column'}
                  flex={1}
                  bg={'blue.400'}
                  px={2}
                  rowGap={1}
                  py={2}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color={'white'}
                  borderRadius={6}
                  onClick={() => handleEditEmailBackup(email)}
                  position={'relative'}
                  maxW={'200px'}
                >
                  <Text>{`Email: ${email.authUserEmail}`}</Text>
                  <Text>{`Password: ${email?.authUserPassword || 'N/A'}`}</Text>
                  <Text>{`Email Sent In Day: ${email?.mailSentInDay}`}</Text>
                  <Text>{`Reach the Limit: ${email?.isLimit ? 'Yes' : 'No'}`}</Text>
                  <Icon as={BsXCircleFill} position={'absolute'} right={0} top={0} onClick={e => handleDeleteEmail(e, email)} />
                </Box>
              ))
            : ''}
        </Flex>
      </Collapse>
      <AddEmailBackupModal
        smtpId={smtpId}
        emailBackup={selectedEmail}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        fetchData={refetch}
      />
    </>
  );
}

export default ExpandedRow;
